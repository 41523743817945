<template>
  <div>
    <section id="hero">
      <v-row no-gutters>
        <v-img
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          src="https://images.unsplash.com/photo-1587620962725-abab7fe55159?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1931&q=80"
        >
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col
                  class="white--text text-center"
                  cols="12"
                  tag="h1"
                >
                  <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                    class="font-weight-black"
                  >
                    WELCOME TO MARCO ANDREOLI
                  </span>

                  <br>

                  <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                    class="text-h6"
                  >
                    FullStack Developer, Supporter and Mentorer
                  </span>

                </v-col>

                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-me')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">CHI SONO</h2>

        <v-responsive
          class="mx-auto mb-8"
          width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          Andreoli Marco, nato il 31/01/1985 a Verona. Sono un Programmatore Web che da 10 anni sviluppo, per l'azienda dove sono dipendente,
          nell'ambito bancario, andando a sviluppare, evolutive prima e da zero poi, portali di Home Corporate Banking.
          <br />
          All'infuori dell'orario di lavoro studio nuove tecnologie che utilizzerò, poi, per progetti personali.
          <br /><br />
          Gestisco una pagina Instagram che tratta di crescita e finanza personale, facendo nascere, così, le prime tracce del mio Personal Brand.
        </v-responsive>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section
      id="features"
      class="grey lighten-3"
    >
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">IL BRAND</h2>

        <v-responsive class="mx-auto mb-12">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ icon, title, text }, i) in features"
            :key="i"
            cols="12"
            md="4"
          >
            <v-card
              class="py-12 px-4"
              color="grey lighten-5"
              flat
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar
                    color="primary"
                    size="88"
                  >
                    <v-icon
                      large
                      v-text="icon"
                    ></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title
                class="justify-center font-weight-black text-uppercase"
                v-text="title"
              ></v-card-title>

              <v-card-text
                class="subtitle-1"
                v-text="text"
              >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
      >
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="3"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-black mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

    <section id="blog">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">PORTFOLIO</h2>

        <v-responsive
          class="mx-auto mb-12"
          width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ imageSrc, src, text, title }, i) in portfoglio"
            :key="i"
            cols="12"
            md="4"
          >
            <v-img
              :src="imageSrc"
              class="mb-4"
              height="275"
              max-width="100%"
            ></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div
              class="title font-weight-light mb-5"
              v-text="text"
            ></div>

            <v-btn
              color="grey"
              :href="src"
              outlined
              large
              target="_blank"
            >
              <span class="grey--text text--darken-1 font-weight-bold">
                Visit
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section
      id="contacts"
      class="grey lighten-3"
    >
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">Contatti</h2>

        <v-responsive
          class="mx-auto mb-8"
          width="56"
        >
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>

        <v-avatar
          class="elevation-12 mb-12"
          size="128"
        >
          <v-img src="img/FotoProfilo.png"></v-img>
        </v-avatar>

        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-card
              class="py-12 px-4"
              color="grey lighten-5"
              flat
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar
                    color="primary"
                    size="88"
                  >
                    <v-icon large>mdi-at</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title class="justify-center font-weight-black text-uppercase">e-mail</v-card-title>

              <v-card-text class="subtitle-1">
                <v-btn
                  color="grey"
                  href="mailto:marco.andreoli1985@gmail.com"
                  outlined
                  large
                  target="_blank"
                >
                  <span class="grey--text text--darken-1 font-weight-bold">
                    marco.andreoli1985@gmail.com
                  </span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-card
              class="py-12 px-4"
              color="grey lighten-5"
              flat
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar
                    color="primary"
                    size="88"
                  >
                    <v-icon large>mdi-linkedin</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title class="justify-center font-weight-black text-uppercase">Profilo Linkedin</v-card-title>

              <v-card-text class="subtitle-1">
                <v-btn
                  color="grey"
                  href="https://www.linkedin.com/in/marco-andreoli/"
                  outlined
                  large
                  target="_blank"
                >
                  <span class="grey--text text--darken-1 font-weight-bold">
                    Marco Andreoli
                  </span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-card
              class="py-12 px-4"
              color="grey lighten-5"
              flat
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar
                    color="primary"
                    size="88"
                  >
                    <v-icon large>mdi-instagram</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title class="justify-center font-weight-black text-uppercase">Profilo Instagram</v-card-title>

              <v-card-text class="subtitle-1">
                <v-btn
                  color="grey"
                  href="https://instagram.com/marcoandreoli_dev"
                  outlined
                  large
                  target="_blank"
                >
                  <span class="grey--text text--darken-1 font-weight-bold">
                    Marco Andreoli
                  </span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-card
              class="py-12 px-4"
              color="grey lighten-5"
              flat
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar
                    color="primary"
                    size="88"
                  >
                    <v-icon large>mdi-post</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title class="justify-center font-weight-black text-uppercase">Blog Personale</v-card-title>

              <v-card-text class="subtitle-1">
                <v-btn
                  color="grey"
                  href="https://losviluppatoreitaliano.wordpress.com/"
                  outlined
                  large
                  target="_blank"
                >
                  <span class="grey--text text--darken-1 font-weight-bold">
                    Marco Andreoli
                  </span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({
    //server??
    features: [
      {
        icon: 'mdi-trophy-outline',
        title: 'Mentoring e Supporting nuovi sviluppatori',
        text: 'Aiuto i giovani sviluppatori che si vogliono affacciare a questo mondo, dando consigli e raccontando esperienze.',
      },
      {
        icon: 'mdi-instagram',
        title: 'Pagina Instagram marcoandreoli_dev',
        text: 'Scorci sulla mia vita privata e da sviluppatore e mini guide sul mondo della programmazione.',
      },
    ],
    stats: [
      ['3', 'Projects in Work'],
      ['1', 'Projects Released'],
      ['8k', 'Total Lines of Code']
    ],
    portfoglio: [
      {
        imageSrc: 'img/ilmondodellibro.png',
        src: 'https://www.ilmondodellibro.eu',
        title: 'ilmondodellibro.eu',
        text: 'Blog che raccoglie le recensioni delle nostre letture. Progetto facente parte dell\'universo del brand Marco Andreoli',
      },
    ],
  }),
}
</script>
